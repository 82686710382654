<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Contentmanagement" style="width:100vw">
  <div id="a6845bc5">
    内容管理
  </div>
  <div id="a2b2b9b9">
    首页轮播Banner
  </div>
  <div id="de33558f">
    <div id="aaff2edb">
      轮播时长
    </div>
    <el-input id="b5247d6d" v-model="inp_b5247d6d">
    </el-input>
    <div id="f152db66">
      秒
    </div>
  </div>
  <div id="ae1d9265">
    <div id="a0b7aaaa" v-for="imginfo in imgList" :key="imginfo.imgID">
      <img :src="imginfo.imgURL" id="be9e0e82">
      <el-button class="ed46d60e"
          @click="clicked46d60e(imginfo)">
        <img src="../assets/img-ed46d60e.png" id="delete">
      </el-button>
      <el-button class="ed46d60e"
          @click="clickeditimginfo(imginfo)"> 编辑
        <!-- <img src="../assets/img-ed46d60e.png" id="delete"> -->
      </el-button>
    </div>
    <div id="ac14b1ac">
      <el-button id="a8da9981" @click="clicka8da9981()">
        添加轮播Banner图片
      </el-button>
    </div>
  </div>
    <div id="a2b2b9b9">
    私域流量建设
  </div>
  <div id="ae1d9265">
    <div id="a0b7aaaa" v-for="imginfo in Privatedata" :key="imginfo.imgID">
      <img :src="imginfo.imgURL" id="be9e0e82">
      <el-button class="ed46d60e"
          @click="clicked46d60e(imginfo)">
        <img src="../assets/img-ed46d60e.png" id="delete">
      </el-button>
      <el-button class="ed46d60e"
          @click="clickeditimginfo(imginfo)"> 编辑
        <!-- <img src="../assets/img-ed46d60e.png" id="delete"> -->
      </el-button>
    </div>
    <div id="ac14b1ac">
      <el-button id="a8da9981" @click="clicka8da9981()">
        添加轮播Banner图片
      </el-button>
    </div>
  </div>

      <div id="a2b2b9b9">
    数字资产化
  </div>
  <div id="ae1d9265">
    <div id="a0b7aaaa" v-for="imginfo in Digitaldata" :key="imginfo.imgID">
      <img :src="imginfo.imgURL" id="be9e0e82">
      <el-button class="ed46d60e"
          @click="clicked46d60e(imginfo)">
        <img src="../assets/img-ed46d60e.png" id="delete">
      </el-button>
      <el-button class="ed46d60e"
          @click="clickeditimginfo(imginfo)"> 编辑
        <!-- <img src="../assets/img-ed46d60e.png" id="delete"> -->
      </el-button>
    </div>
    <div id="ac14b1ac">
      <el-button id="a8da9981" @click="clicka8da9981()">
        添加轮播Banner图片
      </el-button>
    </div>
  </div>

      <div id="a2b2b9b9">
    一体化业务开发平台
  </div>
  <div id="ae1d9265">
    <div id="a0b7aaaa" v-for="imginfo in Integrateddata" :key="imginfo.imgID">
      <img :src="imginfo.imgURL" id="be9e0e82">
      <el-button class="ed46d60e"
          @click="clicked46d60e(imginfo)">
        <img src="../assets/img-ed46d60e.png" id="delete">
      </el-button>
      <el-button class="ed46d60e"
          @click="clickeditimginfo(imginfo)"> 编辑
        <!-- <img src="../assets/img-ed46d60e.png" id="delete"> -->
      </el-button>
    </div>
    <div id="ac14b1ac">
      <el-button id="a8da9981" @click="clicka8da9981()">
        添加轮播Banner图片
      </el-button>
    </div>
  </div>

  <div id="fc95877b">
    首页宣传Banner
  </div>
  <div id="ae015579">
    <img src="../assets/img-adaa0a88.png" id="adaa0a88"/>
  </div>
  <div id="a1d90dfc">
    <el-button id="fb86f55a" @click="clickfb86f55a()">
      确认
    </el-button>
    <el-button id="fe89ca3c" @click="clickfe89ca3c()">
      取消
    </el-button>
  </div>
  <div id="dlgfa09a28a2" v-show="vis_a09a28a2" @click.self="vis_a09a28a2=false">
    <div id="a09a28a2">
      <div id="a0b8d1f3">
        删除Banner
      </div>
      <div id="abab59db">
        删除Banner后，该图片将从首页Banner轮播框中清除，是否删除？
      </div>
      <div id="b4bde515">
        <el-button id="a31ca995" @click="clicka31ca995()">
          确认
        </el-button>
        <el-button id="b760ef0c" @click="clickb760ef0c()">
          取消
        </el-button>
      </div>
    </div>
  </div>
  <div id="dlgfc426d07a" v-show="vis_c426d07a" @click.self="vis_c426d07a=false">
    <div id="c426d07a">
      <div id="a9a04c29">
        温馨提示
      </div>
      <div id="b544f8ac">
        轮播Banner仅剩最后一张，暂不支持删除
      </div>
      <div id="ad7267a3">
        <el-button id="a2b03054" @click="clicka2b03054()">
          确认
        </el-button>
      </div>
    </div>
  </div>
  <div id="dlgfc9168033" v-show="vis_c9168033" @click.self="vis_c9168033=false">
    <div id="c9168033">
      <div id="b9dfda37">
        编辑Banner
      </div>
      <el-upload id="ce812bab" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="updsuc_ce812bab" :before-upload="updbefor_ce812bab">
        <img id="imgce812bab" v-if="imgurl_ce812bab" :src="imgurl_ce812bab"/>
      </el-upload>
      <div id="bfe39fbe">
        <div id="a8f218fa">
          设置跳转页面
        </div>
      <el-radio-group id="f1ae968c" v-model="rdg_f1ae968c" @change="changerdg_edtf1ae968c">
          <el-radio id="c9509f94" label=0>不跳转
          </el-radio>
          <el-radio id="acf2e9ee" label=1>文章
          </el-radio>
          <el-radio id="ac179771" label=2>类别
          </el-radio>
        </el-radio-group>
      </div>
       <div v-show="editJumptoarticle" id="editJumptoarticle"> 输入要跳转的文章ID
        <el-input id="editJumptoarticle" v-model="inp_Jumptoarticle">
        </el-input>
      </div>
      <div v-show="editJumptoclass" id="editJumptoclass"> 选择要跳转的文章类别
        <el-select id="a5190016" placeholder="选择要跳转的文章类别" v-model="val_f395e327">
          <el-option v-for="item in op_f395e327" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- <div id="a5190016">
        <el-select id="dc17b40b" placeholder="选择小程序内部页面" v-model="val_dc17b40b">
          <el-option v-for="item in op_dc17b40b" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <div id="a3b270e6">
        <el-button id="a8e8bd6d" @click="clicka8e8bd6d()">
          确认
        </el-button>
        <el-button id="a7e8a4a1" @click="clicka7e8a4a1()">
          取消
        </el-button>
      </div>
    </div>
  </div>
  <div id="dlgfc3a960f4" v-show="vis_c3a960f4" @click.self="vis_c3a960f4=false">
    <div id="c3a960f4">
      <div id="c696a24c">
        添加轮播Banner
      </div>
      <el-upload
          id="ae0a96f4"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="updsuc_ae0a96f4"
          :before-upload="updbefor_ae0a96f4">
        <img id="imgae0a96f4" v-if="imgurl_ae0a96f4" :src="imgurl_ae0a96f4"/>
      </el-upload>
      <div id="a6cd65cf">
        <div id="def030a0">
          设置跳转页面
        </div>
        <el-radio-group id="f1ae968c" v-model="rdg_f1ae968c" @change="changerdg_f1ae968c">
          <el-radio id="c9509f94" label=0>不跳转
          </el-radio>
          <el-radio id="acf2e9ee" label=1>文章
          </el-radio>
          <el-radio id="ac179771" label=2>类别
          </el-radio>
        </el-radio-group>
      </div>
      <div>
        轮播图位置
      </div>
      <el-select id="a37c88ec"   v-model="val_a37c88ec">
                <el-option v-for="item in op_a37c88ec" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div v-show="Jumptoarticle" id="Jumparticle"> 输入要跳转的文章ID
        <el-input id="Jumptoarticle" v-model="inp_Jumptoarticle">
        </el-input>
      </div>
      <div v-show="Jumptoclass" id="Jumparticle"> 选择要跳转的文章类别
        <el-select id="f395e327" placeholder="选择要跳转的文章类别" v-model="val_f395e327">
          <el-option v-for="item in op_f395e327" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="ad1d1762">
        <el-button id="f723a394" @click="clickf723a394()">
          确认
        </el-button>
        <el-button id="ae89a518" @click="clickae89a518()">
          取消
        </el-button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
  DALINEWEB_IMGCENTER_V0X0X1_addImg,
  DALINEWEB_IMGCENTER_V0X0X1_getImgList,
  DALINEWEB_IMGCENTER_V0X0X1_updateImg,
  DALINEWEB_IMGCENTER_V0X0X1_delImg
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import http from "@/api/restful";

export default{
  data(){
    return{
      inp_b5247d6d:'',
      vis_b5ac4042:false,
      imgurl_a6a412a9:'',
      rdg_a2593fb9:'',
      rd_a529085a:'',
      rd_a47a5ac3:'',
      rd_a1af7528:'',
      op_f395e327:[
        {value: 2,label: '私域流量建设'},
        {value: 3,label: '数字资产化'},
        {value: 4,label: '一体化业务开发平台'}
      ],
      val_f395e327:'',
      vis_a09a28a2:false,
      vis_c426d07a:false,
      vis_c9168033:false,
      imgurl_ce812bab:'',
      rdg_a558d77f:'',
      rd_ae3dbcec:'',
      rd_b9da4db1:'',
      rd_ab18ba02:'',
      op_dc17b40b:[],
      val_dc17b40b:'',
      vis_c3a960f4:false,
      imgurl_ae0a96f4:'',
      rdg_f1ae968c:0,
      rd_c9509f94:'',
      rd_acf2e9ee:'',
      rd_ac179771:'',
      //在此注释下方添加自定义变量
      delStatus: false,
      dwFrontImg: '',
      imgList: [],
      bannerTime: '',
      Jumptoarticle:false,
      Jumptoclass : false,
      inp_Jumptoarticle:'',
      editJumptoarticle:false,
      editJumptoclass:false,
      Privatedata:[],
      Digitaldata:[],
      Integrateddata:[],
      val_a37c88ec:'',
      op_a37c88ec:[
        {key:1,value:'私域流量建设'},
        {key:2,value:"数字资产化"},
        {key:3,value:"一体化业务开发平台"},
        {key:4,value:"首页"},
        {key:5,value:"关于我们"},
      ]
    }
  },
  mounted(){
    this.freshPage(),
    this.freshPrivate(),
    this.freshDigitaldata(),
    this.freshIntegrateddata()
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async changerdg_f1ae968c(value){
      if (value !="0") {
        if (value=="1") {
        this.Jumptoarticle = true
        this.Jumptoclass = false
        } else {
        this.Jumptoclass = true
        this.Jumptoarticle = false
       }
      }
    },
    changerdg_edtf1ae968c(value){
            if (value != 0) {
        if (value==1) {
        this.editJumptoarticle = true
        this.editJumptoclass = false
        } else {
        this.editJumptoarticle = false
        this.editJumptoclass = true
       }
      }
    },
    async clicka8da9981(){
      this.vis_c3a960f4 = true
    },
    async clickfb86f55a(){
    },
    async clickfe89ca3c(){
    },
    async clickeditimginfo(data){
      this.imgID = data.imgID
      this.vis_c9168033 = true
      this.imgurl_ce812bab = data.imgURL
      let jumpPage = data.jumpPage
      if (jumpPage) {
        if (jumpPage>5) {
            this.rdg_f1ae968c = "1"
            this.editJumptoarticle = true
            this.inp_Jumptoarticle = jumpPage
            this.editJumptoclass = false
        }else{
          this.rdg_f1ae968c = "2"
          this.editJumptoarticle = false
          this.editJumptoclass = true
          this.val_f395e327 = jumpPage
        }
      }
      else{
        this.rdg_f1ae968c = "0"
      }
    },
    updsuc_a6a412a9(res,file){
        this.imgurl_a6a412a9= URL.createObjectURL(file.raw);
    },
    updbefor_a6a412a9(file){
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
        }
        return isJPG && isLt2M;
    },
    async clickad6a7ebb(){
    },
    async clickf3f6af7f(){
    },
    async clicka31ca995(){
      DALINEWEB_IMGCENTER_V0X0X1_delImg({ imgID: this.imgID });
      this.vis_a09a28a2 = false;
      this.freshPage();
    },
    async clickb760ef0c(){
      this.vis_a09a28a2 = false;
    },
    async clicka2b03054(){
    },
    async clicked46d60e(e) {
      if (this.delStatus) {
        this.imgID = e.imgID;
        this.vis_a09a28a2 = true;
      } else {
        this.vis_c426d07a = true;
      }
    },
    updsuc_ce812bab(res,file){
        this.imgurl_ce812bab= URL.createObjectURL(file.raw);
        console.log(this.imgurl_ce812bab,"this.imgurl_ce812bab");
    },
    async updbefor_ce812bab(file){
        const isJPG = file.type === 'image/jpeg' || 'image/png' ||'image/gif';
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG,PNG,GIF 格式!');
          return
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
          return
        }
        this.imgurl_ce812bab =await this.getFileURL(file);
        console.log(this.imgurl_ce812bab,"aaaaaa");
        return isJPG && isLt2M;
    },
    async clicka8e8bd6d(){
      let dicInput = {imgID:this.imgID}
      if (this.imgurl_ce812bab != "") {
        dicInput.imgURL = this.imgurl_ce812bab
      } else {
        this.$message.error("上传图片不存在");
        return
      }
      if (this.rdg_f1ae968c == "1") {
         dicInput.jumpPage = this.inp_Jumptoarticle
      }
      if (this.rdg_f1ae968c == "2") {
        dicInput.jumpPage = this.val_f395e327
      }
      await DALINEWEB_IMGCENTER_V0X0X1_updateImg(dicInput);
        this.imgurl_ae0a96f4 = "";
        this.imgurl = "";
        this.vis_c9168033 = false;
        this.freshPage();
    },
    //取消
    async clicka7e8a4a1(){

      this.vis_c9168033 = false;

    },
    updsuc_ae0a96f4(res,file){
        this.imgurl_ae0a96f4= URL.createObjectURL(file.raw);
        console.log(this.imgurl_ae0a96f4,"this.imgurl_ae0a96f4");
    },
    async updbefor_ae0a96f4(file){
        const isJPG = file.type === 'image/jpeg' || 'image/png' ||'image/gif';
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG,PNG,GIF 格式!');
          return
        }
        if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 20MB!');
            return
          }
        this.imgurl_ae0a96f4 =await this.getFileURL(file);
        console.log(this.imgurl_ae0a96f4);
        return isJPG && isLt2M;
    },
    async getFileURL(imgFile) {
      let fd = new FormData();
      fd.append("file", imgFile);
      let uploadURL =
          "/R17771874636-PU-UPLOADSERVER-V0X0X1/pu?type=QueryData&fidx=uploadFile";
      await http
          .post(uploadURL, fd, {
            headers: { "Content-Type": "multipart/form-data" },
            baseURL: "/dalgw",
          })
          .then(
              (res) => {
                console.log("上传成功111", res);
                this.imgurl = res.content.url;
              },
              (err) => {
                console.log("上传失败", err);
                this.$message("上传失败");
              }
          );
                           console.log(this.imgurl,"this.imgurl");
                  return this.imgurl
    },
    async clickf723a394(){
      let today = new Date();
      let day = ("0" + today.getDate()).slice(-2);
      let month = ("0" + (today.getMonth() + 1)).slice(-2);
      let imgName = today.getFullYear() + "-" + month + "-" + day;
      let dicInput = {imgName: imgName}
      switch (this.val_a37c88ec) {
        case "私域流量建设":
          dicInput.imgType = 2
          break;
        case "数字资产化":
          dicInput.imgType = 3
          break;
        case "一体化业务开发平台":
          dicInput.imgType = 4
          break;
        case "首页":
          dicInput.imgType = 5
          break;
        case "关于我们":
          dicInput.imgType = 6
      }
      if (this.imgurl != "") {
        dicInput.imgURL = this.imgurl
      }
      // else {
      //   this.$message.error("上传图片不存在");
      //   return
      // }
      if (this.rdg_f1ae968c == "1") {
         dicInput.jumpPage = this.inp_Jumptoarticle
      }
      if (this.rdg_f1ae968c == "2") {
        dicInput.jumpPage = this.val_f395e327
      }
      console.log(dicInput);
      await DALINEWEB_IMGCENTER_V0X0X1_addImg(dicInput);
        this.imgurl_ae0a96f4 = "";
        this.imgurl = "";
        this.vis_c3a960f4 = false;
        this.freshPage();
    },
    async freshPage() {
      const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList({
        imgType: 5,
      });
      const imgdataList = JSON.parse(JSON.stringify(resImgList.retContent));
      this.imgList = JSON.parse(imgdataList.retContent).imgList
      if (this.imgList.length == 1) {
        this.delStatus = false;
      } else {
        this.delStatus = true;
      }

      this.freshDate();
    },
    async freshDate() {
      this.inp_b5247d6d = this.bannerTime;
    },
    async clickae89a518(){
      this.vis_c3a960f4 = false
    },
    //在此注释下方添加自定义函数或事件
    async freshPrivate(){
            const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList({
        imgType: 2,
      });
      const imgdataList = JSON.parse(JSON.stringify(resImgList.retContent));
      this.Privatedata = JSON.parse(imgdataList.retContent).imgList
      if (this.Privatedata.length == 1) {
        this.delStatus = false;
      } else {
        this.delStatus = true;
      }
    },
        async freshDigitaldata(){
            const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList({
        imgType: 3,
      });
      const imgdataList = JSON.parse(JSON.stringify(resImgList.retContent));
      this.Digitaldata = JSON.parse(imgdataList.retContent).imgList

    },
        async freshIntegrateddata(){
            const resImgList = await DALINEWEB_IMGCENTER_V0X0X1_getImgList({
        imgType: 4,
      });
      const imgdataList = JSON.parse(JSON.stringify(resImgList.retContent));
      this.Integrateddata = JSON.parse(imgdataList.retContent).imgList
    },

  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a6845bc5{
  padding-top:100px;
}
#a6845bc5{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a2b2b9b9{
  margin-top:30px;
}
#a2b2b9b9{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#de33558f{
  width:405px;
  position:relative;
  margin-top:30px;
  padding-left:162px;
}
#aaff2edb{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#aaff2edb{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#de33558f .el-input{
  width:300px;
  margin-left:20px;
  display:inline-block;
  vertical-align: middle;
}
#f152db66{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#f152db66{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#d2c0269e{
  width:1325px;
  position:relative;
  margin-top:30px;
  padding-left:162px;
}
#ade14ae3{
  width:647px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#bcf9cdd0{
  width:647px;
  height:272px;
}
#f0c327f6{
  width:649px;
  position:relative;
  margin-left:29px;
  display:inline-block;
  vertical-align: middle;
}
#a9fe260a{
  width:649px;
  height:272px;
}
#ae1d9265{
  width:1323px;
  position:relative;
  margin-top:30px;
  padding-left:162px;
  display: grid;
  grid-template-columns: 2em,2;
}
#a0b7aaaa{
  width:647px;
  position:relative;
  padding-bottom:0px;
  display:inline-block;
  vertical-align: middle;
}
#be9e0e82{
  width:647px;
  height:272px;
}
#ac14b1ac{
  width:642px;
  position:relative;
  margin-left:34px;
  padding-top:1px;
  padding-bottom:2px;
  display:inline-block;
  vertical-align: middle;
}
#a8da9981{
  width:642px;
}
#a8da9981{
  background-color:transparent;
}
#a8da9981{
  color:#999;
  font-size:18px;
  font-family:PingFang SC;
  border:transparent;
}
#fc95877b{
  margin-top:60px;
}
#fc95877b{
  text-align:right;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#ae015579{
  width:647px;
  position:relative;
  margin-top:30px;
  padding-left:162px;
}
#adaa0a88{
  width:647px;
  height:272px;
}
#a1d90dfc{
  width:446px;
  position:relative;
  margin-top:100px;
  padding-left:594px;
  padding-bottom:253px;
}
#fb86f55a{
  width:158px;
  display:inline-block;
  vertical-align: middle;
}
#fb86f55a{
  background-color:rgb(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
#fb86f55a{
  color:#fff;
  font-size:15px;
  font-family:PingFang SC;
  border:transparent;
}
#fe89ca3c{
  width:158px;
  margin-left:130px;
  display:inline-block;
  vertical-align: middle;
}
#fe89ca3c{
  background-color:rgb(153,153,153,1);
  border-radius:5px 5px 5px 5px;
}
#fe89ca3c{
  color:#fff;
  font-size:15px;
  font-family:PingFang SC;
  border:transparent;
}
#dlgfb5ac4042{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#b5ac4042{
  position:absolute;
  top:248px;
  left:610px;
  width:700px;
  height:584px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#a34220c2{
  padding-top:30px;
}
#a34220c2{
  text-align:left;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#a6a412a9{
  width:219px;
  height:278px;
  margin-top:31px;
  padding-left:244px;
}
#a6a412a9 .el-upload{
  position:absolute;
  width:219px;
  height:278px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
#a6a412a9 .el-upload:hover{
  border-color: #409EFF;
}
 #imga6a412a9{
  width: 178px;  height: 178px;  display: block;}
#a6a412a9 .icona6a412a9{
  font-size: 28px;  color: #8c939d;  width: 178px;  height: 178px;  line-height: 178px;  text-align: center;}
#a57c4ef6{
  width:498px;
  position:relative;
  margin-top:30px;
  padding-left:83px;
}
#a05b3823{
  display:inline-block;
  vertical-align: middle;
}
#a05b3823{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#a2593fb9{
  width:365px;
  margin-left:55px;
  display:inline-block;
  vertical-align: middle;
}
#a2593fb9{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#a529085a{
  width:67px;
  display:inline-block;
  vertical-align: middle;
}
#a47a5ac3{
  width:80px;
  margin-left:69px;
  display:inline-block;
  vertical-align: middle;
}
#a1af7528{
  width:80px;
  margin-left:69px;
  display:inline-block;
  vertical-align: middle;
}
#ac964001{
  width:367px;
  position:relative;
  margin-top:30px;
  padding-left:177px;
}
#ac964001 .el-select{
  width:367px;
}
#f205ff11{
  width:404px;
  position:relative;
  margin-top:30px;
  padding-left:148px;
  padding-bottom:30px;
}
#ad6a7ebb{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#ad6a7ebb{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#ad6a7ebb{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#f3f6af7f{
  width:142px;
  margin-left:120px;
  display:inline-block;
  vertical-align: middle;
}
#f3f6af7f{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#f3f6af7f{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#dlgfa09a28a2{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#a09a28a2{
  position:absolute;
  top:422px;
  left:610px;
  width:700px;
  height:237px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#a0b8d1f3{
  padding-top:30px;
}
#a0b8d1f3{
  text-align:left;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#abab59db{
  margin-top:43px;
}
#abab59db{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#b4bde515{
  width:404px;
  position:relative;
  margin-top:43px;
  padding-left:148px;
  padding-bottom:40px;
}
#a31ca995{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#a31ca995{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a31ca995{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#b760ef0c{
  width:142px;
  margin-left:120px;
  display:inline-block;
  vertical-align: middle;
}
#b760ef0c{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#b760ef0c{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#dlgfc426d07a{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#c426d07a{
  position:absolute;
  top:422px;
  left:611px;
  width:698px;
  height:237px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#a9a04c29{
  padding-top:30px;
}
#a9a04c29{
  text-align:center;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#b544f8ac{
  margin-top:43px;
}
#b544f8ac{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#ad7267a3{
  width:142px;
  position:relative;
  margin-top:43px;
  padding-left:278px;
  padding-bottom:40px;
}
#a2b03054{
  width:142px;
}
#a2b03054{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a2b03054{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#dlgfc9168033{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#c9168033{
  position:absolute;
  top:248px;
  left:610px;
  width:700px;
  height:584px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#b9dfda37{
  padding-top:30px;
}
#b9dfda37{
  text-align:left;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#ce812bab{
  width:219px;
  height:278px;
  margin-top:31px;
  padding-left:244px;
}
#ce812bab .el-upload{
  position:absolute;
  width:219px;
  height:278px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
#ce812bab .el-upload:hover{
  border-color: #409EFF;
}
 #imgce812bab{
  width: 178px;  height: 178px;  display: block;}
#ce812bab .iconce812bab{
  font-size: 28px;  color: #8c939d;  width: 178px;  height: 178px;  line-height: 178px;  text-align: center;}
#bfe39fbe{
  width:498px;
  position:relative;
  margin-top:30px;
  padding-left:83px;
}
#a8f218fa{
  display:inline-block;
  vertical-align: middle;
}
#a8f218fa{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#a558d77f{
  width:365px;
  margin-left:55px;
  display:inline-block;
  vertical-align: middle;
}
#a558d77f{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#ae3dbcec{
  width:67px;
  display:inline-block;
  vertical-align: middle;
}
#b9da4db1{
  width:80px;
  margin-left:69px;
  display:inline-block;
  vertical-align: middle;
}
#ab18ba02{
  width:80px;
  margin-left:69px;
  display:inline-block;
  vertical-align: middle;
}
#a5190016{
  width:367px;
  position:relative;
  margin-top:30px;
  padding-left:177px;
}
#a5190016 .el-select{
  width:367px;
}
#a3b270e6{
  width:404px;
  position:relative;
  margin-top:30px;
  padding-left:148px;
  padding-bottom:30px;
}
#a8e8bd6d{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#a8e8bd6d{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a8e8bd6d{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#a7e8a4a1{
  width:142px;
  margin-left:120px;
  display:inline-block;
  vertical-align: middle;
}
#a7e8a4a1{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#a7e8a4a1{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#dlgfc3a960f4{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#c3a960f4{
  position:absolute;
  top:120px;
  left:310px;
  width:1200px;
  height:620px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#c696a24c{
  padding-top:30px;
}
#c696a24c{
  text-align:left;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#ae0a96f4{
  width:219px;
  height:278px;
  margin-top:31px;
  padding-left:244px;
}
#ae0a96f4 .el-upload{
  position:absolute;
  width:219px;
  height:278px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
#ae0a96f4 .el-upload:hover{
  border-color: #409EFF;
}
 #imgae0a96f4{
  width: 178px;  height: 178px;  display: block;}
#ae0a96f4 .iconae0a96f4{
  font-size: 28px;  color: #8c939d;  width: 178px;  height: 178px;  line-height: 178px;  text-align: center;}
#a6cd65cf{
  width:498px;
  position:relative;
  margin-top:30px;
  padding-left:82px;
}
#def030a0{
  display:inline-block;
  vertical-align: middle;
}
#def030a0{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#f1ae968c{
  width:365px;
  margin-left:55px;
  display:inline-block;
  vertical-align: middle;
}
#f1ae968c{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#c9509f94{
  width:67px;
  display:inline-block;
  vertical-align: middle;
}
.ed46d60e {
  width: 26px;
  height: 26px;
  margin-left: 20px;
  margin-top: 20px;
  display: inline-block;
  vertical-align: middle;
}
.ed46d60e {
  line-height: 26px;
  background-color: #fff;
  padding: 0px;
}
#acf2e9ee{
  width:80px;
  margin-left:69px;
  display:inline-block;
  vertical-align: middle;
}
#ac179771{
  width:80px;
  margin-left:69px;
  display:inline-block;
  vertical-align: middle;
}
#ad1d1762{
  width:404px;
  position:relative;
  margin-top:40px;
  padding-left:148px;
  padding-bottom:30px;
}
#f723a394{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#f723a394{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#f723a394{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#ae89a518{
  width:142px;
  margin-left:120px;
  display:inline-block;
  vertical-align: middle;
}
#ae89a518{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#ae89a518{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
#Jumptoarticle{
  width: 300px;
  margin-left: 100px;
}
#Jumparticle{
  margin-left: 80px;
}
#delete{
  z-index: 101;
}
</style>
